import { FC } from 'react';
import IconProps from './IconProps';

const PhoneIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 2.66667C1 1.74619 1.74619 1 2.66667 1H5.39937C5.75806 1 6.07651 1.22952 6.18993 1.56981L7.43808 5.31434C7.56925 5.70777 7.39117 6.13776 7.02025 6.32322L5.13917 7.26375C6.05771 9.301 7.699 10.9423 9.73625 11.8608L10.6767 9.97975C10.8622 9.60883 11.2922 9.43075 11.6857 9.56192L15.4302 10.8101C15.7705 10.9235 16 11.2419 16 11.6007V14.3333C16 15.2538 15.2538 16 14.3333 16H13.5C6.59644 16 1 10.4036 1 3.5V2.66667Z"
        stroke="#86BC25"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PhoneIcon;
